import { useEffect, useReducer, useState } from 'react';
import getConfig from 'next/config';
import { TextField } from '@fabric-ds/react';
import { DatePickerCalendar, DatePickerInput, DatePickerPopover, DateRangePicker } from '@finn-no/legacy-fabric-datepicker';

import { t } from '../../locale/texts';
import { getLastSearchFhh, storeLastSearchFhh } from '../common/lastSearch';
import { fhhAutocompleteMapper } from '../common/autocompleteMapper';
import { trackSearchClick } from '../common/tracking';

import actions, { reducer } from './fhhActions';
import { fhhDefaultState } from './fhhDefaultState';

import { Dropdown } from '../common/Dropdown';
import { Autocomplete } from '../common/Autocomplete';
import { BedroomFilter } from './BedroomFilter';
import { FhhSearchSubmit } from './FhhSearchSubmit';
import { isMobile } from '../../util/mobile';
import { isPast } from '../../util/datetime-fns';

import { FrontpageDatePickerWrapper } from '../../components/frontpageDatePickerWrapper/FrontpageDatePickerWrapper';

type FhhSearchProps = {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    prefill?: Record<string, any>;
    widget?: string;
    position: string;
    unleash?: Record<string, boolean>;
};

const {
    publicRuntimeConfig: { fhhAutocompleteUrl, fhhResultUrl },
} = getConfig();

export const FhhSearch = ({ prefill, widget, position, unleash }: FhhSearchProps) => {
    const [pristine, setPristine] = useState(true);
    const [state, dispatch] = useReducer(reducer, fhhDefaultState);

    useEffect(() => {
        dispatch(actions.allFieldsChanged({ ...getLastSearchFhh(), ...prefill }));
    }, []);

    const validateState = (): boolean => state.destination.trim() === '' || !!state.destinationData;

    const handleFilterChange = (filters): void => {
        if (filters.no_of_bedrooms_from !== undefined) {
            dispatch(actions.setNumOfBedrooms(filters.no_of_bedrooms_from));
        }
        if (filters.no_of_beds_from !== undefined) {
            dispatch(actions.setNumOfBeds(filters.no_of_beds_from));
        }
    };

    const handleSubmit = (event): void => {
        if (validateState()) {
            storeLastSearchFhh(state);

            const item = {
                id: 'finntravel',
                type: 'Travel',
                destination: state.destination,
                travelType: 'holiday_rental',
                numberOfRooms: state.numOfBedrooms,
                numberOfBeds: state.numOfBeds,
            };
            trackSearchClick('Search holiday rentals', '/reise/feriehus', 'holiday_rental', position, item);
        } else {
            setPristine(false);
            event.preventDefault();
        }
    };

    const bedroomText = `${state.numOfBedrooms ? t('fhh.bedroom', { count: state.numOfBedrooms }) : t('fhh.bedroom_all')}, ${
        state.numOfBeds ? t('fhh.bed', { count: state.numOfBeds }) : t('fhh.bed_all')
    }`;

    const numberOfMonths = isMobile() ? 1 : 2;

    const invalid = {
        startDate: !pristine && state.endDate && !state.startDate,
        endDate: !pristine && state.startDate && !state.endDate,
    };

    return (
        <section className="bg-aqua-50 rounded-8 p-16" data-testid="fhhSearch" aria-label={t('fhh.title')}>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-16">
                <Autocomplete
                    id="destination"
                    label={t('fhh.destination.label')}
                    placeholder={t('fhh.destination.placeholder')}
                    defaultValue={state.destination}
                    url={fhhAutocompleteUrl}
                    minChars={2}
                    mapOptions={fhhAutocompleteMapper}
                    error={!pristine && !state.destinationData ? t('fhh.validation.destination') : ''}
                    onSelect={(option) => dispatch(actions.setDestination(option))}
                    className="lg:col-span-2"
                />
                {unleash?.basicDatePicker ? (
                    <FrontpageDatePickerWrapper
                        startDate={state.startDate}
                        endDate={state.endDate}
                        onDateChange={({ startDate, endDate }) => dispatch(actions.setDates({ startDate, endDate }))}>
                        <TextField
                            id="date-fhh-checkin"
                            label={t('fhh.checkin.label')}
                            aria-label={t('fhh.checkin.label')}
                            data-testid="date-fhh-checkin"
                            error={invalid.startDate}
                        />
                        <TextField
                            id="date-fhh-checkout"
                            label={t('fhh.checkout.label')}
                            aria-label={t('fhh.checkout.label')}
                            data-testid="date-fhh-checkout"
                            error={invalid.endDate}
                        />
                    </FrontpageDatePickerWrapper>
                ) : (
                    <DateRangePicker
                        startDate={state.startDate}
                        endDate={state.endDate}
                        isDayDisabled={isPast}
                        minimumNights={1}
                        onChange={(newDates) => dispatch(actions.setDates(newDates))}>
                        <div className="grid grid-cols-2 gap-x-16">
                            <DatePickerInput
                                dateField="startDate"
                                as={TextField}
                                id="date-fhh-checkin"
                                label={t('fhh.checkin.label')}
                                aria-label={t('fhh.checkin.label')}
                                placeholder={t('fhh.checkin.placeholder')}
                                data-testid="date-fhh-checkin"
                                error={invalid.startDate}
                            />
                            <DatePickerInput
                                dateField="endDate"
                                as={TextField}
                                id="date-fhh-checkout"
                                label={t('fhh.checkout.label')}
                                aria-label={t('fhh.checkout.label')}
                                placeholder={t('fhh.checkout.placeholder')}
                                data-testid="date-fhh-checkout"
                                error={invalid.endDate}
                            />
                        </div>
                        {/* TS2590: Expression produces a union type that is too complex to represent. we have no power here, so just ignoring it :(  */}
                        {/* @ts-ignore */}
                        <DatePickerPopover className="date-popover">
                            <DatePickerCalendar numberOfMonths={numberOfMonths} />
                        </DatePickerPopover>
                    </DateRangePicker>
                )}
                <Dropdown id="bedsAndBedrooms" label={t('fhh.bedrooms')} text={bedroomText}>
                    <BedroomFilter
                        filters={{ no_of_beds_from: state.numOfBeds, no_of_bedrooms_from: state.numOfBedrooms }}
                        onFilterChanged={handleFilterChange}
                    />
                </Dropdown>
            </div>
            <FhhSearchSubmit {...state} handleSubmit={handleSubmit} resultUrl={fhhResultUrl} widget={widget} />
        </section>
    );
};
