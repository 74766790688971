import { useState, useEffect } from 'react';

interface BreakpointsProps {
    breakpoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | number;
}

const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
};

const getBreakpointValue = (breakpoint: BreakpointsProps['breakpoint']): number => {
    return typeof breakpoint === 'number' ? breakpoint : breakpoints[breakpoint];
};

export function useBreakpoints({ breakpoint }: BreakpointsProps): boolean {
    const breakpointValue = getBreakpointValue(breakpoint);
    const [isSmallScreen, setIsSmallScreen] = useState(() => (typeof window !== 'undefined' ? window.innerWidth < breakpointValue : false));

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < breakpointValue);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [breakpointValue]);

    return isSmallScreen;
}
