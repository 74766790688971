import { PriceLevelType } from '../../../util/calendar';
import cn from 'classnames';

import styles from './PriceLevelDisplay.module.css';

interface PriceLevelDisplayProps {
    priceLevel: PriceLevelType | undefined;
    className?: string;
}

export const PriceLevelDisplay = ({ priceLevel, className }: PriceLevelDisplayProps) => {
    const priceMap = {
        [PriceLevelType.GREEN]: '$',
        [PriceLevelType.YELLOW]: '$$',
        [PriceLevelType.RED]: '$$$',
    };

    const displayValue = priceMap[priceLevel as PriceLevelType] || '';

    const ariaLabel = {
        [PriceLevelType.GREEN]: 'Laveste prisnivå',
        [PriceLevelType.YELLOW]: 'Medium prisnivå',
        [PriceLevelType.RED]: 'Dyreste prisnivå',
    };

    return (
        <span
            title={ariaLabel[priceLevel as PriceLevelType]}
            className={cn(styles.priceLevelDisplay, { [styles.priceLevelDisplayDefault]: !displayValue }, className)}>
            {displayValue}
        </span>
    );
};
