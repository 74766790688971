import useSWR from 'swr';

import { addDays, formatISODate } from '../../util/datetime-fns';
import { queryString } from '../../util/query';

export type UsePriceLevelsOptions = {
    url: string;
    tripType: 'oneway' | 'roundtrip' | 'openjaw';
    origin?: string;
    destination?: string;
    startDate?: Date | number;
    endDate?: Date | number;
    directOnly?: boolean;
};

type PriceLevel = 'GREEN' | 'YELLOW' | 'RED';

type PriceLevels = {
    [date: string]: PriceLevel;
};

/*
    Fetches lowfare price level data for given origin-destination withing specified date range.
    Usage: const { data, error, isLoading } = usePriceLevels({ <params> });
*/

export const usePriceLevels = ({ url, tripType, origin, destination, startDate, endDate, directOnly }: UsePriceLevelsOptions) => {
    const shouldFetch = (tripType === 'oneway' || tripType === 'roundtrip') && origin && destination;
    const key = shouldFetch
        ? {
              tripType,
              requestedOrigin: origin,
              requestedDestination: destination,
              startDate: formatISODate(startDate ?? new Date()),
              endDate: formatISODate(endDate ?? addDays(365, new Date())),
              directOnly,
          }
        : null;

    const priceLevelsFetcher = (params: Record<string, string>) =>
        fetch(`${url}${queryString(params)}`).then((res) =>
            res.ok ? Promise.resolve(res.json()) : Promise.reject(`(${res.status}) ${res.statusText}: ${res.url}`),
        );

    return useSWR<PriceLevels, Error>(key, priceLevelsFetcher, { revalidateOnFocus: false });
};
