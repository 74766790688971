import { BasicDatePicker, type RangeSelectionProps } from '../basicDatePicker/BasicDatePicker';
import cn from 'classnames';

import styles from './FrontpageDatePickerWrapper.module.css';

export const FrontpageDatePickerWrapper = ({
    startDate,
    endDate,
    onDateChange,
    className,
    modalFooter,
    upperDateLimit,
    children,
}: Omit<RangeSelectionProps, 'useRange' | 'visibleMonths'>) => {
    return (
        <BasicDatePicker
            className={cn(styles.FrontpageDatePicker, className)}
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            useRange={true}
            visibleMonths={2}
            modalFooter={modalFooter}
            upperDateLimit={upperDateLimit}>
            {children}
        </BasicDatePicker>
    );
};
