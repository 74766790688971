import { type RefObject, useEffect } from 'react';

const useKeyboardNavigation = (
    focusedDate: Date,
    setFocusedDate: (date: Date) => void,
    modalRef: RefObject<HTMLDivElement>,
    hideCalendar: () => void,
    handleNextPrevMonth: (direction: 'prev' | 'next') => void,
    visibleMonths: number,
    displayDates: Date,
) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            let newDate: Date | null = null;

            const firstDayOfNextMonth = new Date(focusedDate.getFullYear(), focusedDate.getMonth() + 1, 1);
            const firstDayOfPrevMonth = new Date(focusedDate.getFullYear(), focusedDate.getMonth() - 1, 1);
            const lastDayOfPrevMonth = new Date(focusedDate.getFullYear(), focusedDate.getMonth(), 0);
            const nextMonthFirstDay = new Date(displayDates.getFullYear(), displayDates.getMonth() + visibleMonths, 1);

            const today = new Date();

            today.setHours(0, 0, 0, 0);

            const focusDay = (date: Date) => {
                requestAnimationFrame(() => {
                    const focusedDay = modalRef.current?.querySelector(`[data-date="${date.toISOString()}"]`) as HTMLElement;
                    if (focusedDay) focusedDay.focus();
                });
            };

            const focusFirstDay = () => {
                requestAnimationFrame(() => {
                    const firstDay = modalRef.current?.querySelector('.date-picker__day:not([disabled])') as HTMLElement;
                    if (firstDay) firstDay.focus();
                });
            };

            const focusLastDay = () => {
                requestAnimationFrame(() => {
                    const lastFocusableDay = Array.from(
                        modalRef.current?.querySelectorAll('.date-picker__day:not([disabled])') || [],
                    ).pop() as HTMLElement;
                    if (lastFocusableDay) lastFocusableDay.focus();
                });
            };

            const getFirstDayOfVisibleMonths = () => {
                return new Date(displayDates.getFullYear(), displayDates.getMonth(), 1);
            };

            const getLastDayOfVisibleMonths = () => {
                return new Date(displayDates.getFullYear(), displayDates.getMonth() + visibleMonths, 0);
            };

            const isFirstDayOfVisibleMonths = (date: Date) => {
                const firstDay = getFirstDayOfVisibleMonths();

                return (
                    date.getFullYear() === firstDay.getFullYear() &&
                    date.getMonth() === firstDay.getMonth() &&
                    date.getDate() === firstDay.getDate()
                );
            };

            const isLastDayOfVisibleMonths = (date: Date) => {
                const lastDay = getLastDayOfVisibleMonths();

                return (
                    date.getFullYear() === lastDay.getFullYear() &&
                    date.getMonth() === lastDay.getMonth() &&
                    date.getDate() === lastDay.getDate()
                );
            };

            const isTopRowOfFirstMonth = (date: Date) => {
                const firstDay = getFirstDayOfVisibleMonths();
                return date.getDate() <= 7 && date.getMonth() === firstDay.getMonth() && date.getFullYear() === firstDay.getFullYear();
            };

            const isBottomRowOfLastMonth = (date: Date) => {
                const lastDay = getLastDayOfVisibleMonths();
                const daysInMonth = lastDay.getDate();

                return (
                    date.getDate() > daysInMonth - 7 &&
                    date.getMonth() === lastDay.getMonth() &&
                    date.getFullYear() === lastDay.getFullYear()
                );
            };

            switch (event.key) {
                case 'PageUp':
                    event.preventDefault();
                    setFocusedDate(firstDayOfPrevMonth);
                    handleNextPrevMonth('prev');
                    focusFirstDay();

                    break;

                case 'PageDown':
                    event.preventDefault();
                    setFocusedDate(firstDayOfNextMonth);
                    handleNextPrevMonth('next');
                    focusFirstDay();

                    break;

                case 'ArrowUp':
                    if (isTopRowOfFirstMonth(focusedDate)) {
                        event.preventDefault();

                        if (lastDayOfPrevMonth < today) return;

                        setFocusedDate(lastDayOfPrevMonth);
                        handleNextPrevMonth('prev');
                        focusLastDay();

                        break;
                    }

                    newDate = new Date(focusedDate);
                    newDate.setDate(focusedDate.getDate() - 7);

                    break;

                case 'ArrowDown':
                    if (isBottomRowOfLastMonth(focusedDate)) {
                        event.preventDefault();
                        setFocusedDate(firstDayOfNextMonth);
                        handleNextPrevMonth('next');
                        focusFirstDay();

                        return;
                    }

                    newDate = new Date(focusedDate);
                    newDate.setDate(focusedDate.getDate() + 7);

                    break;

                case 'ArrowLeft':
                    if (isFirstDayOfVisibleMonths(focusedDate)) {
                        event.preventDefault();
                        const prevMonthLastDay = new Date(displayDates.getFullYear(), displayDates.getMonth(), 0);
                        setFocusedDate(prevMonthLastDay);
                        handleNextPrevMonth('prev');
                        focusDay(prevMonthLastDay);

                        return;
                    }

                    newDate = new Date(focusedDate);
                    newDate.setDate(focusedDate.getDate() - 1);

                    break;

                case 'ArrowRight':
                    if (isLastDayOfVisibleMonths(focusedDate)) {
                        event.preventDefault();
                        setFocusedDate(nextMonthFirstDay);
                        handleNextPrevMonth('next');
                        focusFirstDay();

                        return;
                    }

                    newDate = new Date(focusedDate);
                    newDate.setDate(focusedDate.getDate() + 1);

                    break;

                case 'Escape':
                    hideCalendar();
                    (document.activeElement as HTMLElement)?.blur();

                    return;

                default:
                    return;
            }

            if (newDate) {
                event.preventDefault();

                const firstDayOfVisibleMonths = getFirstDayOfVisibleMonths();
                const lastDayOfVisibleMonths = getLastDayOfVisibleMonths();

                if (newDate < firstDayOfVisibleMonths) {
                    newDate = firstDayOfVisibleMonths;
                } else if (newDate > lastDayOfVisibleMonths) {
                    newDate = lastDayOfVisibleMonths;
                }

                setFocusedDate(newDate);
                focusDay(newDate);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [focusedDate, handleNextPrevMonth, hideCalendar, setFocusedDate, modalRef, visibleMonths, displayDates]);

    return null;
};

export default useKeyboardNavigation;
