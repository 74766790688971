import { addMonths } from 'date-fns';

import type { Dispatch, SetStateAction } from 'react';

export enum Direction {
    Left = 'left',
    Right = 'right',
}

export enum PriceLevelType {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED',
}

export const weekdayNames = {
    no: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

export const monthNames = {
    no: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Desember'],
};

export enum CountryCodes {
    NO = 'no',
    SE = 'se',
}

export enum Languages {
    EN = 'en',
    NO = 'no',
}

export enum Currencies {
    NOK = 'nok',
    SEK = 'sek',
}

export const resetHours = (date: Date | null) => date?.setHours(0, 0, 0, 0);

// Format date to Norwegian dd.mm.yyyy
export const formatDate = (date: Date | null) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

// Parse Norwegian format dd.mm.yyyy to Date object
export const parseDate = (dateString: string): Date | null => {
    const [day, month, year] = dateString.split('.').map(Number);
    const parsedDate = new Date(year, month - 1, day);
    return !Number.isNaN(parsedDate.getTime()) ? parsedDate : null;
};

export const handleDateInputChange = (
    dateString: string,
    setInputValue: Dispatch<SetStateAction<string>>,
    setDate: Dispatch<SetStateAction<Date | null>>,
    parseDateFunc: (dateString: string) => Date | null,
    isEndDate: boolean,
    setEndDateFirst: Dispatch<SetStateAction<boolean>>,
    setIsStartDateFocused: Dispatch<SetStateAction<boolean>>,
    setIsEndDateFocused: Dispatch<SetStateAction<boolean>>,
) => {
    setInputValue(dateString);

    if (dateString.trim() === '') {
        setDate(null);

        if (isEndDate) {
            setEndDateFirst(false);
            setIsEndDateFocused(true);
        } else {
            setIsStartDateFocused(true);
        }
        return;
    }

    // Validate the format (e.g., dd.mm.yyyy for Norwegian dates)
    const validFormatRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    if (validFormatRegex.test(dateString)) {
        const parsedDate = parseDateFunc(dateString);

        if (parsedDate) {
            setDate(parsedDate);
        } else {
            setDate(null);
        }
    } else {
        setDate(null);
    }
};

export const handleAutoFormatOnBlur = (
    dateString: string,
    setInputValue: Dispatch<SetStateAction<string>>,
    setDate: Dispatch<SetStateAction<Date | null>>,
    parseDateFunc: (dateString: string) => Date | null,
) => {
    const cleanedInput = dateString.replace(/[^\d]/g, '');

    if (cleanedInput.length === 6 || cleanedInput.length === 8) {
        let year = cleanedInput.slice(4, 6);
        if (cleanedInput.length === 6) {
            year = `20${year}`;
        } else {
            year = cleanedInput.slice(4, 8);
        }
        const formattedInput = `${cleanedInput.slice(0, 2)}.${cleanedInput.slice(2, 4)}.${year}`;
        setInputValue(formattedInput);
        const parsedDate = parseDateFunc(formattedInput);
        if (parsedDate) {
            setDate(parsedDate);
        }
    } else {
        setInputValue(dateString);
    }
};

function getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
}

export function getPreviousMonthInfo(year: number, month: number) {
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    const numDaysInPrevMonth = getDaysInMonth(prevYear, prevMonth);
    return { prevMonth, prevYear, numDaysInPrevMonth };
}

export const getISOWeekNumber = (date: Date): number => {
    const tempDate = new Date(date.getTime());
    tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7));
    const startOfYear = new Date(tempDate.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((tempDate.getTime() - startOfYear.getTime()) / 86400000 + startOfYear.getDay() + 1) / 7);

    return weekNumber;
};

export const formatDateTime = (dateString: Date) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
};

export const formatDateAriaLabel = (date: Date): string =>
    date.toLocaleDateString('no-NO', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

export const getOneYearFromToday = (): Date => addMonths(new Date(), 12);
