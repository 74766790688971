import { useState } from 'react';
import cn from 'classnames';

import { IconChevronUp16 } from '@fabric-ds/icons/react';

import { PriceLevelType } from '../../../util/calendar';
import { PriceLevelDisplay } from '../PriceLevelDisplay/PriceLevelDisplay';

import styles from './ExplanationBox.module.css';

interface ExplanationBoxProps {
    className?: string;
}

export const ExplanationBox = ({ className }: ExplanationBoxProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <details className={cn(styles.explanationBox, className)} onToggle={handleToggle}>
            <summary className={styles.summary} aria-expanded={isOpen} aria-label="Les mer om pris nivå symbolene">
                <PriceLevelDisplay className={styles.priceLevelDisplay} priceLevel={PriceLevelType.GREEN} />
                <PriceLevelDisplay className={styles.priceLevelDisplay} priceLevel={PriceLevelType.YELLOW} />
                <PriceLevelDisplay className={styles.priceLevelDisplay} priceLevel={PriceLevelType.RED} />
                <span className={styles.summaryTitle}>
                    Les mer
                    <IconChevronUp16 aria-hidden="true" className={`${styles.chevronDownIcon} ${!isOpen && styles.rotate}`} />
                </span>
            </summary>
            <div className={styles.explanationContent}>
                <strong>Symbolene viser anslått pris per dato for én person</strong>
                <dl className={styles.descriptiveList}>
                    <dt>
                        <PriceLevelDisplay className={styles.priceLevelDisplay} priceLevel={PriceLevelType.GREEN} />
                    </dt>
                    <dd>laveste pris</dd>
                    <dt>
                        <PriceLevelDisplay className={styles.priceLevelDisplay} priceLevel={PriceLevelType.YELLOW} />
                    </dt>
                    <dd>medium pris</dd>
                    <dt>
                        <PriceLevelDisplay className={styles.priceLevelDisplay} priceLevel={PriceLevelType.RED} />
                    </dt>
                    <dd>dyreste pris</dd>
                </dl>
                <p>
                    Hvis du ser en dato uten prissymbol, betyr det at vi ikke klarte å finne noen pris på denne dagen. Det er fortsatt mulig
                    å finne flyvninger ved å søke.
                </p>
                <p>Bagasje, antall stopp, totaltid og andre filtreringer vil ikke gjenspeiles i prissymbolene.</p>
            </div>
        </details>
    );
};
