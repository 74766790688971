import { type FormEvent, useEffect } from 'react';

import { t } from '../../locale/texts';
import { safeFormatClientDate } from '../../util/datetime-fns';
import { addGclidToLocalStorage, addLabelToLocalStorage } from './referral';
import type { HotelRoom } from './HotelRooms';
import type { HotelAutocompleteOption } from './HotelSearchForm';
import { createSubmitUrl, createWhitelabelUrl } from './kayakWhitelabel';

const roomsToQueryParams = (rooms?: HotelRoom[]) =>
    rooms && rooms.length > 0
        ? rooms.map((room) => (room.children.length > 0 ? `${room.adults}-${room.children.join('.')}` : `${room.adults}`))
        : undefined;

type HotelSearchSubmitProps = {
    resultUrl: string;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    rooms?: HotelRoom[];
    startDate?: Date;
    endDate?: Date;
    destinationContext?: HotelAutocompleteOption;
    widget?: string;
    position?: string;
    unleash?: Record<string, boolean>;
};

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
const placeNameFromKey = (destinationContext?: Record<string, any>): string =>
    destinationContext?.placeKey?.charAt(0).toUpperCase() + destinationContext?.placeKey?.slice(1);

export const HotelSearchSubmit = ({
    resultUrl,
    handleSubmit,
    destinationContext,
    startDate,
    endDate,
    rooms,
    widget,
    position,
    unleash,
}: HotelSearchSubmitProps) => {
    useEffect(() => {
        addGclidToLocalStorage();
        addLabelToLocalStorage();
    }, []);

    const checkInDate = safeFormatClientDate(startDate);
    const checkOutDate = safeFormatClientDate(endDate);
    const showDate = destinationContext?.searchable && checkInDate && checkOutDate;
    const displayDestination = destinationContext?.text ?? placeNameFromKey(destinationContext);

    const OldForm = () => (
        <form onSubmit={handleSubmit} action={createSubmitUrl(resultUrl, destinationContext)} method="GET" data-testid="hotelSearchForm">
            {showDate ? (
                <>
                    <input data-testid="checkInDate" type="hidden" name="checkInDate" value={checkInDate} />
                    <input data-testid="checkOutDate" type="hidden" name="checkOutDate" value={checkOutDate} />
                </>
            ) : null}
            {destinationContext?.searchable &&
                (roomsToQueryParams(rooms) || []).map((room, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: This was set before biome was added
                    <input key={`room-${index}`} data-testid={`room-${index}`} type="hidden" name="rooms" value={room} />
                ))}

            {widget && (
                <>
                    <input type="hidden" name="utm_source" value={widget} />
                    <input type="hidden" name="utm_medium" value="widget" />
                    <input type="hidden" name="utm_campaign" value="hotell" />
                </>
            )}

            <button
                type="submit"
                className="button button--primary px-64 w-full max-w-full sm:w-auto sm:max-w-auto mt-24"
                data-testid="hotelSearchButton">
                {t('action.search')}
            </button>
            {position === 'destinationpages' && destinationContext && (
                <strong>
                    <a
                        href={`${createSubmitUrl(resultUrl, destinationContext)}?rooms=2&sort=popularity&sortDirection=descending`}
                        className="mt-24 mr-4 float-right">
                        Se alle hotell i {displayDestination}
                    </a>
                </strong>
            )}
        </form>
    );

    const WhitelabelForm = () => (
        <form onSubmit={handleSubmit} action="https://travel-kayak-wl.finn.no/in" method="GET" data-testid="hotelSearchForm">
            <input
                data-testid="whiteLabelUrl"
                type="hidden"
                name="url"
                value={createWhitelabelUrl(destinationContext, startDate, endDate, rooms)}
            />
            <button
                type="submit"
                className="button button--primary px-64 w-full max-w-full sm:w-auto sm:max-w-auto mt-24"
                data-testid="hotelSearchButton">
                {t('action.search')}
            </button>
        </form>
    );

    return unleash?.kayakWhitelabel ? <WhitelabelForm /> : <OldForm />;
};
