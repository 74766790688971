import { useCallback } from 'react';

import { formatDate, resetHours } from 'shared/util/calendar';

interface UpdateDateRangeProps {
    useRange: boolean;
    startDate: Date | null;
    endDate: Date | null;
    endDateFirst: boolean;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
    setInputStartDate: (dateString: string) => void;
    setInputEndDate: (dateString: string) => void;
    setEndDateFirst: (flag: boolean) => void;
    setIsStartDateFocused: (flag: boolean) => void;
    setIsEndDateFocused: (flag: boolean) => void;
}

export const useUpdateDateRange = ({
    useRange,
    startDate,
    endDate,
    endDateFirst,
    setStartDate,
    setEndDate,
    setInputStartDate,
    setInputEndDate,
    setEndDateFirst,
    setIsStartDateFocused,
    setIsEndDateFocused,
}: UpdateDateRangeProps) => {
    const updateDateRange = useCallback(
        (date: Date) => {
            const resetDate = resetHours(new Date(date));
            const normalizedDate = new Date(resetDate ?? date);

            if (!useRange) {
                setStartDate(normalizedDate);
                setEndDate(null);
                setInputStartDate(formatDate(normalizedDate));
                setInputEndDate('');
                setIsStartDateFocused(true);
                setIsEndDateFocused(false);
                return;
            }

            if (startDate && endDate && startDate.getTime() === endDate.getTime() && normalizedDate.getTime() === startDate.getTime()) {
                setStartDate(null);
                setEndDate(null);
                setInputStartDate('');
                setInputEndDate('');
                setIsStartDateFocused(true);
                setIsEndDateFocused(false);
                setEndDateFirst(false);
                return;
            }

            if (endDate && normalizedDate.getTime() === endDate.getTime()) {
                setEndDate(null);
                setInputEndDate('');
                setIsStartDateFocused(false);
                setIsEndDateFocused(true);
                return;
            }

            if (startDate && endDate) {
                setStartDate(normalizedDate);
                setEndDate(null);
                setInputStartDate(formatDate(normalizedDate));
                setInputEndDate('');
                setIsStartDateFocused(false);
                setIsEndDateFocused(true);
                setEndDateFirst(false);
                return;
            }

            if (endDateFirst) {
                if (!startDate && !endDate) {
                    setEndDate(normalizedDate);
                    setInputEndDate(formatDate(normalizedDate));
                    setIsStartDateFocused(true);
                    setIsEndDateFocused(false);
                    return;
                }

                if (!startDate && endDate) {
                    if (normalizedDate < endDate) {
                        setStartDate(normalizedDate);
                        setInputStartDate(formatDate(normalizedDate));
                        setIsStartDateFocused(false);
                        setIsEndDateFocused(true);
                    } else {
                        setEndDate(normalizedDate);
                        setInputEndDate(formatDate(normalizedDate));
                        setIsStartDateFocused(true);
                        setIsEndDateFocused(false);
                    }
                }

                if (startDate && endDate) {
                    setEndDateFirst(false);
                }

                return;
            }

            if (!startDate) {
                setStartDate(normalizedDate);
                setInputStartDate(formatDate(normalizedDate));
                setIsStartDateFocused(false);
                setIsEndDateFocused(true);
                return;
            }

            if (startDate && !endDate && normalizedDate >= startDate) {
                setEndDate(normalizedDate);
                setInputEndDate(formatDate(normalizedDate));
                setIsStartDateFocused(false);
                setIsEndDateFocused(true);
                return;
            }

            if (startDate && normalizedDate < startDate) {
                setStartDate(normalizedDate);
                setEndDate(null);
                setInputStartDate(formatDate(normalizedDate));
                setInputEndDate('');
                setIsStartDateFocused(false);
                setIsEndDateFocused(true);
                return;
            }
        },
        [
            useRange,
            startDate,
            endDate,
            endDateFirst,
            setStartDate,
            setEndDate,
            setInputStartDate,
            setInputEndDate,
            setEndDateFirst,
            setIsStartDateFocused,
            setIsEndDateFocused,
        ],
    );

    return updateDateRange;
};
