import { memo, type PropsWithChildren, type ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import { formatDateAriaLabel, formatDateTime } from 'shared/util/calendar';

interface DatePickerDayProps {
    getDate: number;
    displayDates: Date;
    isDisabled: boolean;
    onClick: () => void;
    isFocused?: boolean;
    className: string;
    children?: ReactNode;
}

export const DatePickerDay = memo(
    ({ getDate, displayDates, className, isDisabled, onClick, isFocused, children }: PropsWithChildren<DatePickerDayProps>) => {
        const dayRef = useRef<HTMLButtonElement>(null);

        useEffect(() => {
            if (isFocused && dayRef.current) {
                dayRef.current.focus();
            }
        }, [isFocused]);

        return (
            <td aria-hidden={isDisabled} className="date-picker__calendar__table-cell">
                <button
                    ref={dayRef}
                    className={cn('date-picker__day', className)}
                    onClick={onClick}
                    type="button"
                    disabled={isDisabled}
                    aria-disabled={isDisabled}
                    tabIndex={isFocused ? -1 : 0}
                    data-date={displayDates.toISOString()}
                    aria-selected={isFocused ? 'true' : undefined}>
                    <time aria-hidden="true" className="date-picker__day__date-time" dateTime={formatDateTime(displayDates)}>
                        {getDate}
                    </time>
                    <span className="sr-only">{formatDateAriaLabel(displayDates)}</span>
                    {children}
                </button>
            </td>
        );
    },
);
