import { type RefObject, useCallback, useEffect, useRef } from 'react';

interface SwipeOptions {
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
    threshold?: number;
}

export const useSwipe = (elementRef: RefObject<HTMLElement>, { onSwipeLeft, onSwipeRight, threshold = 50 }: SwipeOptions) => {
    const touchStartX = useRef<number | null>(null);
    const touchEndX = useRef<number | null>(null);

    const handleTouchStart = useCallback((e: TouchEvent) => {
        touchStartX.current = e.touches[0].clientX;
        touchEndX.current = null;
    }, []);

    const handleTouchMove = useCallback((e: TouchEvent) => {
        touchEndX.current = e.touches[0].clientX;
    }, []);

    const handleTouchEnd = useCallback(() => {
        if (touchStartX.current === null || touchEndX.current === null) return;

        const diffX = touchEndX.current - touchStartX.current;

        if (diffX > threshold) {
            onSwipeRight();
        } else if (diffX < -threshold) {
            onSwipeLeft();
        }

        touchStartX.current = null;
        touchEndX.current = null;
    }, [onSwipeLeft, onSwipeRight, threshold]);

    useEffect(() => {
        const element = elementRef.current;

        if (!element) return;

        element.addEventListener('touchstart', handleTouchStart as EventListener);
        element.addEventListener('touchmove', handleTouchMove as EventListener);
        element.addEventListener('touchend', handleTouchEnd as EventListener);

        return () => {
            element.removeEventListener('touchstart', handleTouchStart as EventListener);
            element.removeEventListener('touchmove', handleTouchMove as EventListener);
            element.removeEventListener('touchend', handleTouchEnd as EventListener);
        };
    }, [elementRef, handleTouchStart, handleTouchMove, handleTouchEnd]);

    return null;
};
