import { type RefObject, useEffect } from 'react';

const useClickOutside = (modalRef: RefObject<HTMLDivElement>, hideCalendar: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                hideCalendar();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [hideCalendar, modalRef]);

    return null;
};

export default useClickOutside;
