import { type RefObject, useCallback, useMemo } from 'react';

import { useSwipe } from './useSwipe';

interface DateNavigationProps {
    visibleMonths: number;
    displayDates: { getFullYear: () => number; getMonth: () => number };
    setDisplayDates: (newDate: Date) => void;
    modalRef: RefObject<HTMLDivElement>;
    upperDateLimit?: Date;
}

export const useDateNavigation = ({ visibleMonths, displayDates, setDisplayDates, modalRef, upperDateLimit }: DateNavigationProps) => {
    const today = useMemo(() => new Date(), []);

    const getNewDate = useCallback(
        (action: 'prev' | 'next') => {
            const currentYear = displayDates.getFullYear();
            const currentMonth = displayDates.getMonth();
            return new Date(currentYear, currentMonth + (action === 'next' ? visibleMonths : -visibleMonths), 1);
        },
        [displayDates, visibleMonths],
    );

    const isDateInPast = useCallback(
        (date: Date) => {
            return date < new Date(today.getFullYear(), today.getMonth(), 1);
        },
        [today],
    );

    const isDateBeyondLimit = useCallback(
        (date: Date) => {
            return upperDateLimit ? date > new Date(upperDateLimit.getFullYear(), upperDateLimit.getMonth(), 1) : false;
        },
        [upperDateLimit],
    );

    const handleNextPrevMonth = useCallback(
        (action: 'prev' | 'next') => {
            const targetDate = getNewDate(action);
            if (action === 'prev' && isDateInPast(targetDate)) return;
            if (action === 'next' && isDateBeyondLimit(targetDate)) return;
            setDisplayDates(targetDate);
        },
        [getNewDate, isDateInPast, isDateBeyondLimit, setDisplayDates],
    );

    const isPrevDisabled = displayDates.getFullYear() === today.getFullYear() && displayDates.getMonth() <= today.getMonth();

    const isNextDisabled = useMemo(() => {
        if (!upperDateLimit) return false;
        const nextDate = getNewDate('next');
        return isDateBeyondLimit(nextDate);
    }, [getNewDate, isDateBeyondLimit, upperDateLimit]);

    useSwipe(modalRef, {
        onSwipeLeft: () => handleNextPrevMonth('next'),
        onSwipeRight: () => handleNextPrevMonth('prev'),
    });

    return { handleNextPrevMonth, isPrevDisabled, isNextDisabled, modalRef };
};
