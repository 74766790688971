import { useEffect, useCallback, type RefObject } from 'react';

const focusableSelectors = [
    'a[href]',
    'button:not([disabled])',
    'textarea:not([disabled])',
    "input[type='text']:not([disabled])",
    'select:not([disabled])',
    "[tabindex]:not([tabindex='-1'])",
    'summary',
];

export const useFocusTrap = (isActive: boolean, modalRef: RefObject<HTMLElement>, options?: { shouldFocusFirstElement?: boolean }) => {
    const { shouldFocusFirstElement = true } = options || {};

    const getFocusableElements = useCallback(() => {
        if (!modalRef.current) return [];
        return Array.from(modalRef.current.querySelectorAll(focusableSelectors.join(','))) as HTMLElement[];
    }, [modalRef]);

    useEffect(() => {
        const modalElement = modalRef.current;
        const focusableElements = getFocusableElements();
        const firstElement = focusableElements[0];

        if (!isActive || !modalElement) return;

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Tab') {
                const focusableElements = getFocusableElements();
                const firstElement = focusableElements[0];
                const lastElement = focusableElements[focusableElements.length - 1];

                if (e.shiftKey) {
                    if (document.activeElement === firstElement) {
                        e.preventDefault();
                        lastElement?.focus();
                    }
                } else {
                    if (document.activeElement === lastElement) {
                        e.preventDefault();
                        firstElement?.focus();
                    }
                }
            }
        };

        if (shouldFocusFirstElement && firstElement) {
            firstElement.focus();
        }

        modalElement.addEventListener('keydown', handleKeyDown);
        return () => modalElement.removeEventListener('keydown', handleKeyDown);
    }, [isActive, modalRef, getFocusableElements, shouldFocusFirstElement]);

    return { getFocusableElements };
};
