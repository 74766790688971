import { useEffect } from 'react';

export const useInitializeDateRange = ({
    initialStartDate,
    initialEndDate,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setInputStartDate,
    setInputEndDate,
    formatDate,
}: {
    initialStartDate: Date | null;
    initialEndDate: Date | null;
    startDate: Date | null;
    endDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
    setInputStartDate: (dateString: string) => void;
    setInputEndDate: (dateString: string) => void;
    formatDate: (date: Date | null) => string;
}) => {
    useEffect(() => {
        if (!startDate) {
            setStartDate(initialStartDate);
            setInputStartDate(formatDate(initialStartDate));
        }
    }, [initialStartDate]);

    useEffect(() => {
        if (!endDate) {
            setEndDate(initialEndDate);
            setInputEndDate(formatDate(initialEndDate));
        }
    }, [initialEndDate]);
};
