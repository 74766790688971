import cn from 'classnames';
import { type Languages, weekdayNames } from 'shared/util/calendar';

type WeekdayHeaderProps = {
    language: Languages;
    offsetStartDay?: number;
    slice?: number;
    upperDateLimit?: boolean;
};

export const WeekdayHeader = ({ upperDateLimit, language, offsetStartDay = 1, slice = 3 }: WeekdayHeaderProps) => {
    const weekdayNamesArr = weekdayNames[language] || [];
    const shiftedWeekdayNames = [...weekdayNamesArr.slice(offsetStartDay), ...weekdayNamesArr.slice(0, offsetStartDay)];

    return (
        <thead>
            <tr
                className={cn('date-picker__weekday-header', {
                    'date-picker__weekday-header--outside-range': upperDateLimit,
                })}>
                {shiftedWeekdayNames.map((weekdayName) => (
                    <th scope="col" className="date-picker__weekday-header-day" key={weekdayName}>
                        <abbr title={weekdayName}>{weekdayName.slice(0, slice)}</abbr>
                    </th>
                ))}
            </tr>
        </thead>
    );
};
