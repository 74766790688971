import { getPreviousMonthInfo } from 'shared/util/calendar';
import type { SharedDatePickerProps } from '../types/DatePickerProps';

export function generateDateItems(
    year: number,
    month: number,
    firstDayOfMonth: number,
    numDaysInMonth: number,
    startDate: SharedDatePickerProps['startDate'],
    endDate: SharedDatePickerProps['endDate'],
    hidePaddingDays: SharedDatePickerProps['hidePaddingDays'],
    upperDateLimit?: Date,
): { date: Date; classNames: string[]; isDisabled: boolean }[] {
    const dates: { date: Date; classNames: string[]; isDisabled: boolean }[] = [];
    const today = new Date();

    const getClassNamesForDate = (currentDate: Date, padding: boolean) => {
        const classNames: string[] = [];

        if (padding) {
            classNames.push('date-picker__day--non-current');

            if (hidePaddingDays) {
                classNames.push('date-picker__day--non-current-hidden');
            }
        } else {
            classNames.push('current-month');
        }

        const isToday =
            currentDate.getDate() === today.getDate() &&
            currentDate.getMonth() === today.getMonth() &&
            currentDate.getFullYear() === today.getFullYear();

        if (isToday) {
            classNames.push('date-picker__day--today');
        }

        if (currentDate < today && !isToday) {
            classNames.push('date-picker__day--expired');
        }

        if (upperDateLimit && currentDate > upperDateLimit) {
            classNames.push('date-picker__day--outside-range');
        }

        if (startDate && currentDate.getTime() === startDate.getTime()) {
            classNames.push('date-picker__day--start');
        }

        if (endDate && currentDate.getTime() === endDate.getTime()) {
            classNames.push('date-picker__day--end');
        }

        if (startDate && endDate && currentDate > startDate && currentDate < endDate) {
            classNames.push('date-picker__day--between');
        }

        return classNames;
    };

    const paddingDaysStart = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    if (paddingDaysStart > 0) {
        const { prevMonth, prevYear, numDaysInPrevMonth } = getPreviousMonthInfo(year, month);
        const prevMonthStart = numDaysInPrevMonth - paddingDaysStart;

        for (let i = prevMonthStart + 1; i <= numDaysInPrevMonth; i++) {
            const paddingDate = new Date(prevYear, prevMonth, i);
            const classNames = getClassNamesForDate(paddingDate, true);

            dates.push({
                date: paddingDate,
                classNames,
                isDisabled: true,
            });
        }
    }

    for (let i = 1; i <= numDaysInMonth; i++) {
        const currentDate = new Date(year, month, i);
        const classNames = getClassNamesForDate(currentDate, false);
        const isDisabled =
            (currentDate < today && !classNames.includes('date-picker__day--today')) ||
            (upperDateLimit !== undefined && currentDate > upperDateLimit);

        dates.push({ date: currentDate, classNames, isDisabled });
    }

    const totalDays = dates.length;
    const paddingDaysEnd = (7 - (totalDays % 7)) % 7;

    if (paddingDaysEnd > 0 && totalDays + paddingDaysEnd <= 42) {
        const nextMonth = month === 11 ? 0 : month + 1;
        const nextYear = month === 11 ? year + 1 : year;

        for (let i = 1; i <= paddingDaysEnd; i++) {
            const paddingDate = new Date(nextYear, nextMonth, i);
            const classNames = getClassNamesForDate(paddingDate, true);

            dates.push({
                date: paddingDate,
                classNames,
                isDisabled: true,
            });
        }
    }

    return dates;
}
