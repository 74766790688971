import { type ReactElement, type ReactNode, useMemo, useState } from 'react';
import cn from 'classnames';

import { addMonths, endOfMonth, setDate, startOfMonth, subMonths } from 'date-fns';
import type { TextField } from '@fabric-ds/react';
import getConfig from 'next/config';

import { getOneYearFromToday, type PriceLevelType } from '../../util/calendar';

import { ExplanationBox } from './ExplanationBox/ExplanationBox';
import { BasicDatePicker, type RangeSelectionProps, type SingleSelectionProps } from '../basicDatePicker/BasicDatePicker';
import { usePriceLevels } from '../../search/common/usePriceLevels';
import { PriceLevelDisplay } from './PriceLevelDisplay/PriceLevelDisplay';

import styles from './MetaDatePickerWrapper.module.css';
import { useBreakpoints } from '../../hooks/useBreakpoints';

interface MetaDatePickerWrapperProps {
    tripType: 'oneway' | 'roundtrip' | 'openjaw';
    origin: string;
    destination: string;
}

const {
    publicRuntimeConfig: { flightApiUrl },
} = getConfig();

export const MetaDatePickerWrapper = ({
    tripType,
    origin,
    destination,
    useRange,
    children,
    startDate,
    endDate,
    onDateChange,
    visibleMonths,
    className,
}: MetaDatePickerWrapperProps & (SingleSelectionProps | RangeSelectionProps)) => {
    const [displayedDate, setDisplayedDate] = useState(new Date());

    const isSmallScreen = useBreakpoints({ breakpoint: 'sm' });

    const dynamicStartDate = useMemo(() => {
        if (isSmallScreen) {
            return startOfMonth(subMonths(displayedDate, 1));
        }
        return setDate(subMonths(startOfMonth(displayedDate), 1), 15);
    }, [displayedDate, isSmallScreen]);

    const dynamicEndDate = useMemo(() => {
        if (isSmallScreen) {
            return endOfMonth(addMonths(displayedDate, 1));
        }
        return setDate(addMonths(displayedDate, 2), 15);
    }, [displayedDate, isSmallScreen]);

    const { data } = usePriceLevels({
        url: `${flightApiUrl}/lowfare/calendar`,
        tripType,
        origin,
        destination,
        startDate: dynamicStartDate,
        endDate: dynamicEndDate,
    });

    const { dateLevels, dateData } = useMemo(() => {
        if (!Array.isArray(data)) {
            return { dateLevels: {}, dateData: {} };
        }

        return data.reduce(
            (acc, { date, level }) => {
                acc.dateLevels[date] = level;
                acc.dateData[date] = <PriceLevelDisplay priceLevel={level as PriceLevelType} />;
                return acc;
            },
            {
                dateLevels: {} as Record<string, string>,
                dateData: {} as Record<string, ReactNode>,
            },
        );
    }, [data]);

    const formatDynamicClassName = (date: Date) => {
        const isoDate = date.toISOString().split('T')[0];
        const level = dateLevels?.[isoDate] || '';
        return level.toLowerCase();
    };

    if (useRange) {
        return (
            <BasicDatePicker
                className={cn(styles.MetaDatePicker, className)}
                modalFooter={<ExplanationBox />}
                dateData={dateData}
                dayClassName={formatDynamicClassName}
                onDateChange={onDateChange}
                visibleMonths={visibleMonths}
                useRange={true}
                startDate={startDate}
                endDate={endDate}
                setDisplayedDate={setDisplayedDate}
                upperDateLimit={getOneYearFromToday()}>
                {children as [ReactElement<typeof TextField>, ReactElement<typeof TextField>]}
            </BasicDatePicker>
        );
    }

    return (
        <BasicDatePicker
            className={cn(styles.MetaDatePicker, className)}
            modalFooter={<ExplanationBox />}
            dateData={dateData}
            dayClassName={formatDynamicClassName}
            onDateChange={onDateChange}
            visibleMonths={visibleMonths}
            useRange={false}
            startDate={startDate}
            setDisplayedDate={setDisplayedDate}
            upperDateLimit={getOneYearFromToday()}>
            {children as ReactElement<typeof TextField>}
        </BasicDatePicker>
    );
};
